import { isPlatformServer } from '@angular/common';
import * as i0 from '@angular/core';
import { InjectionToken, PLATFORM_ID, NgModule } from '@angular/core';
import { Storage } from '@ionic/storage';
export { Storage } from '@ionic/storage';
const StorageConfigToken = new InjectionToken('STORAGE_CONFIG_TOKEN');
class NoopStorage extends Storage {
  constructor() {
    super();
  }
  async create() {
    return this;
  }
  async defineDriver() {}
  get driver() {
    return 'noop';
  }
  async get(key) {
    return null;
  }
  async set(key, value) {}
  async remove(key) {}
  async clear() {}
  async length() {
    return 0;
  }
  async keys() {
    return [];
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  async forEach(iteratorCallback) {}
  setEncryptionKey(key) {}
}
function provideStorage(platformId, storageConfig) {
  if (isPlatformServer(platformId)) {
    // When running in a server context return the NoopStorage
    return new NoopStorage();
  }
  return new Storage(storageConfig);
}
class IonicStorageModule {
  static forRoot(storageConfig = null) {
    return {
      ngModule: IonicStorageModule,
      providers: [{
        provide: StorageConfigToken,
        useValue: storageConfig
      }, {
        provide: Storage,
        useFactory: provideStorage,
        deps: [PLATFORM_ID, StorageConfigToken]
      }]
    };
  }
}
IonicStorageModule.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "15.1.2",
  ngImport: i0,
  type: IonicStorageModule,
  deps: [],
  target: i0.ɵɵFactoryTarget.NgModule
});
IonicStorageModule.ɵmod = i0.ɵɵngDeclareNgModule({
  minVersion: "14.0.0",
  version: "15.1.2",
  ngImport: i0,
  type: IonicStorageModule
});
IonicStorageModule.ɵinj = i0.ɵɵngDeclareInjector({
  minVersion: "12.0.0",
  version: "15.1.2",
  ngImport: i0,
  type: IonicStorageModule
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "15.1.2",
  ngImport: i0,
  type: IonicStorageModule,
  decorators: [{
    type: NgModule
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { IonicStorageModule, StorageConfigToken, provideStorage };
